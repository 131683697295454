import React, { useState } from "react";
import { Link } from "react-router-dom";
import Head from "./Head";
import "./header.css";

const Header = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click); // Toggle the click state
  };

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav flexSB" : "flexSB"}>
            <li>
              <Link to='/'>Domov</Link>
            </li>
            <li>
              <Link to='/about'>O nás</Link>
            </li>
            <li>
              <Link to='/pricing'>Ponuka kurzov</Link>
            </li>
            <li>
              <Link to='/contact'>Kontakt</Link>
            </li>
          </ul>
      
        


        </nav>
      </header>
    </>
  );
};

export default Header;
