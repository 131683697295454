import React from "react";
import { Link } from "react-router-dom";
import { price } from "../../dummydata"; // Assuming price array is imported correctly
import "./PriceCard.css";

const PriceCard = () => {
  return (
    <>
      {price.map((val, index) => (
        <div className="items shadow description" key={index}>
          <h1>{val.price}</h1>
          <span>1 účastník za 1 hodinu</span>

          <Link
            to={{
              pathname: "/contact",
              state: { selectedOption: val.desc }, // Pass the selected option as state
            }}
          >
            <button className="outline-btn">Objednať</button>
          </Link>
          <p className="description">{val.desc}</p>
          
        </div>
      ))}
    </>
  );
};

export default PriceCard;

