import React from "react"
import { useLocation } from "react-router-dom"
import './back.css'

const Back = ({ title }) => {
  const location = useLocation()

  return (
    <>
      <section>
      <p className="back">{title}</p>
      </section>
    </>
  )
}

export default Back
