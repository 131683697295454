import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
           
          </div>
        </div>
      </section>
      <div className='margin'>
      <p>Komplexné vzdelávanie pre kvalitnú starostlivosť a prevenciu sociálno-patologických javov.</p>

      </div>
    </>
  )
}

export default Hero
